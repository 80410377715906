<template>
  <div>
    <div class="tab-bar">
      <div
          v-for="item in meetingRoomTypes"
          :key="item.type"
          :class="{ active: item.type == type }"
          @click="changeTabBar(item.type)"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="filter-panel">
      <CSSelect style="margin-right: 10px; width: 185px">
        <el-date-picker
            v-model="startDate"
            :editable="false"
            format="yyyy-MM-dd"
            placeholder="请选择开始时间"
            prefix-icon="el-icon-time"
            style="width: 157px"
            type="date"
            value-format="yyyy-MM-dd"
        ></el-date-picker>
      </CSSelect>
      <span
          style="
          display: inline-block;
          vertical-align: middle;
          margin: 0 0 15px 0;
          height: 2px;
          width: 20px;
          margin-right: 0;
          background-color: #999;
        "
      >
      </span>
      <CSSelect style="margin-left: 10px; width: 185px">
        <el-date-picker
            v-model="endDate"
            :editable="false"
            format="yyyy-MM-dd"
            placeholder="请选择结束时间"
            prefix-icon="el-icon-time"
            style="width: 157px"
            type="date"
            value-format="yyyy-MM-dd"
        ></el-date-picker>
      </CSSelect>
      <input
          v-model="userInfo"
          class="cs-input"
          placeholder="搜索用户信息"
          type="text"
      />
      <div class="cs-select" style="margin-right: 30px">
        <select v-model="state" style="padding-left: 6px">
          <option value="">订单状态不限</option>
          <template v-if="type === MEETING_ROOM_TYPE.PUBLIC">
            <option
                v-for="(state, key) in orderStates"
                :key="key"
                :value="key"
                style="color: #000"
            >
              {{ state }}
            </option>
          </template>
          <template v-else>
            <option
                v-for="(state, key) in oldStates"
                :key="key"
                :value="key"
                style="color: #000"
            >
              {{ state }}
            </option>
          </template>
        </select>
        <i class="icon el-icon-caret-bottom"></i>
      </div>
      <button
          class="query_"
          style="vertical-align: middle"
          @click="getMeetingRoomOrders()"
      >
        查询
      </button>
    </div>

    <div class="result-panel">
      <template v-if="type === MEETING_ROOM_TYPE.PUBLIC">
        <CSTable :thead-top="filterHeight">
          <template v-slot:header>
            <div class="table-header-panel" style="margin-bottom: 0">
              累计已付金额: {{ orderList.paymentTotal || "-" }} 元
            </div>
          </template>
          <template v-slot:thead>
            <tr>
              <th class="text-center">预约时间</th>
              <th class="text-center">用户信息</th>
              <th class="text-center">会议室房间号</th>
              <th class="text-center">单价</th>
              <th class="text-center">实际使用时间</th>
              <th class="text-center">已付金额</th>
              <th class="text-center">已退金额</th>
              <th class="text-center">预约使用时间</th>
              <th class="text-center">用户评价内容</th>
              <th class="text-center">订单状态</th>
              <th class="text-center">操作</th>
            </tr>
          </template>
          <template v-slot:tbody>
            <template v-if="orderList.datas && orderList.datas.length > 0">
              <tr v-for="(order, index) in orderList.datas" :key="index">
                <td class="text-center date-td">
                  {{ order.createTime || "-" }}
                </td>
                <td class="text-center">
                  <div>
                    <p style="margin-bottom: 0px">
                      {{ order.userName }}
                      【{{ order.userPhone }}】
                    </p>
                    <p style="margin-bottom: 0px">
                      {{ order.userCompany }}
                    </p>
                    <p style="margin-bottom: 0px">
                      {{ order.companyLocation }}
                    </p>
                  </div>
                </td>
                <td class="text-center">
                  <span> {{ order.buildingName + "-" + order.name }}</span>
                </td>
                <td class="text-center">
                  {{ order.price + "元" }}
                </td>
                <td class="text-center">
                  {{ order.actualUseTime || "-" }}
                </td>
                <td class="text-center">
                  <template v-if="order.state === ORDER_STATE.PAID">
                    {{ order.payMoney ? order.payMoney + "元" : "-" }}
                  </template>
                  <template v-else>-</template>
                </td>
                <td class="text-center">
                  {{ order.refundMoney ? order.refundMoney + "元" : "-" }}
                </td>
                <td class="text-center">
                  {{ order.reservationTime }}
                  <p style="margin-bottom: 0px">
                    {{ order.reservationDuration.split(" ")[0] }}
                  </p>
                  <p style="margin-bottom: 0px">
                    {{ order.reservationDuration.split(" ")[1] }}
                  </p>
                </td>
                <td class="text-center">
                  <template v-if="order.score">
                    <button class="btn btn-link" @click="lookEvaluate(order)">
                      查看
                    </button>
                  </template>
                  <template v-else>-</template>
                </td>
                <td class="text-center">
                  {{ orderStates[order.state] || "-" }}
                </td>
                <td class="text-center">
                  <div
                      v-if="[1, 2, 3].includes(order.paymentState)"
                      class="btn-group"
                  >
                    <button
                        aria-expanded="false"
                        aria-haspopup="true"
                        class="btn btn-primary dropdown-toggle"
                        data-toggle="dropdown"
                        type="button"
                    >
                      操作
                    </button>
                    <ul class="dropdown-menu">
                      <li v-if="order.payMoney && order.paymentState === 1">
                        <a @click="openRefundModal(order)"> 退款 </a>
                      </li>
                      <li v-if="[2, 3].includes(order.paymentState)">
                        <a
                            style="width: 100%"
                            @click="openOfflinePaymentDialog(order)"
                        >线下付款
                        </a>
                      </li>
                    </ul>
                  </div>
                  <template v-else>-</template>
                </td>
              </tr>
            </template>
          </template>
        </CSTable>
      </template>
      <template v-else>
        <CSTable :thead-top="filterHeight">
          <template v-slot:thead>
            <tr>
              <th class="text-center">预约时间</th>
              <th class="text-center">用户信息</th>
              <th class="text-center">会议室房间号</th>
              <th class="text-center">实际使用时间</th>
              <th class="text-center">预约使用时间</th>
              <th class="text-center">订单状态</th>
            </tr>
          </template>
          <template v-slot:tbody>
            <template v-if="orderList.datas && orderList.datas.length > 0">
              <tr
                  v-for="(order, index) in orderList.datas"
                  :key="index"
                  class="table_style"
              >
                <td class="text-center date-td">
                  {{ order.createTime || "-" }}
                </td>
                <td class="text-center">
                  <div>
                    <p style="margin-bottom: 0px">
                      {{ order.userName }}
                      【{{ order.userPhone }}】
                    </p>
                    <p style="margin-bottom: 0px">
                      {{ order.userCompany }}
                    </p>
                    <p style="margin-bottom: 0px">
                      {{ order.companyLocation }}
                    </p>
                  </div>
                </td>
                <td class="text-center">
                  <span class="allow-click" @click="lookMeetingRoomNo(order)">{{
                      order.buildingName + "-" + order.name
                    }}</span>
                </td>
                <td class="text-center">
                  {{ order.actualUseTime || "-" }}
                </td>
                <td class="text-center">
                  {{ order.reservationTime }}
                  <p style="margin-bottom: 0px">
                    {{ order.reservationDuration }}
                  </p>
                </td>
                <td class="text-center">
                  {{ oldStates[order.state] || "-" }}
                </td>
              </tr>
            </template>
          </template>
        </CSTable>
      </template>
      <Pagination/>
    </div>
    <ViewModal></ViewModal>
    <RefundChargeModal
        :detail-id="refundModalInfo.detailId"
        :money="refundModalInfo.money"
        :refresh-order="refundModalInfo.refresh"
        :visible="refundModalInfo.visible"
        @closeModal="closeRefundModal"
    ></RefundChargeModal>
    <!-- 点击线下付款后显示的弹出框 -->
    <CSDialog
        :dialogVisible="offlinePaymentDialog.visible"
        dialogConfirmBtnText="确定"
        dialogTitle="线下付款"
        dialogWidth="960px"
        @onClose="offlinePaymentDialog.visible = false"
        @onConfirm="offlinePay"
    >
      <div
          slot="dialog-content"
          class="offline-payment-dialog"
          style="padding: 20px 30px; font-size: 24px"
      >
        <div class="box_">
          <label class="hy-label"> 支付渠道 </label>
          <div style="display: inline-block; vertical-align: middle">
            <span style="margin-right: 20px">
              <input
                  v-model="offlinePaymentDialog.data.payChannel"
                  :value="PAY_CHANNEL.CASH_PAY"
                  style="margin-right: 10px; vertical-align: middle"
                  type="radio"
              />
              现金支付
            </span>
            <span style="margin-right: 20px">
              <input
                  v-model="offlinePaymentDialog.data.payChannel"
                  :value="PAY_CHANNEL.BANK_PAY"
                  style="margin-right: 10px; vertical-align: middle"
                  type="radio"
              />银行转账
            </span>
            <span style="margin-right: 20px">
              <input
                  v-model="offlinePaymentDialog.data.payChannel"
                  :value="PAY_CHANNEL.ALI_PAY"
                  style="margin-right: 10px; vertical-align: middle"
                  type="radio"
              />
              支付宝转账
            </span>
            <span style="margin-right: 20px">
              <input
                  v-model="offlinePaymentDialog.data.payChannel"
                  :value="PAY_CHANNEL.WE_CHAT_PAY"
                  style="margin-right: 10px; vertical-align: middle"
                  type="radio"
              />
              微信转账
            </span>
          </div>
        </div>
        <!--                <div style="margin-bottom: 15px;">
                                    <label class="hy-label">应付金额</label>
                                    <i style="vertical-align: middle;">{{ offlinePaymentDialog.data.payableMoney }} 元</i>
                                </div>-->
        <div style="margin-bottom: 25px">
          <label class="hy-label">已付金额</label>
          <input
              v-model="offlinePaymentDialog.data.moneyAmount"
              class="cs-input"
              placeholder="请输入"
              style="
              margin-left: 0;
              border-radius: 4px;
              border: 1px solid #979797;
              padding-left: 10px;
              vertical-align: middle;
            "
              type="text"
          />
          元
        </div>
        <div style="margin-bottom: 27px">
          <label class="hy-remarks">付款凭证</label>
          <div style="display: inline-block">
            <div
                v-if="offlinePaymentDialog.data.paymentVoucher === ''"
                class="upload-photo"
                @click="triggerUploadElement('#uploadVoucherPhoto')"
            >
              <div class="text-center" style="padding-bottom: 0">
                <img
                    alt=""
                    src="../../assets/upload.png"
                    style="margin: 5px auto"
                />
                <p style="color: #999">上传照片</p>
              </div>
            </div>
            <div
                v-else
                class="photo-view"
                style="background: #fff; border: 1px solid rgb(151, 151, 151)"
                @click="triggerUploadElement('#uploadVoucherPhoto')"
            >
              <img :src="offlinePaymentDialog.data.paymentVoucher"/>
            </div>
            <input
                id="uploadVoucherPhoto"
                accept="images/*"
                class="file"
                hidden
                type="file"
                @change="choosePhoto($event)"
            />
          </div>
        </div>
        <div>
          <label class="hy-remarks">备注</label>
          <textarea
              v-model="offlinePaymentDialog.data.desc"
              placeholder="请输入"
          ></textarea>
        </div>
      </div>
    </CSDialog>
  </div>
</template>

<script>
import ViewModal from "@/components/ViewModal";
import RefundChargeModal from "@/components/RefundChargeModal";
import Pagination from "@/components/Pagination.vue";
import CSSelect from "@/components/common/CSSelect";
import {
  closeDoorSettlementUrl,
  meetingRoomOfflinePaymentUrl,
  queryMeetingRoomOrderUrl,
  sweepCodeToOpenDoorlUrl,
} from "@/requestUrl";
import dayjs from "dayjs";
import CSTable from "@/components/common/CSTable";
import {PAY_CHANNEL} from "@/constant";
import CSDialog from "@/components/common/CSDialog";

// 订单状态
const ORDER_STATE = {
  // 已预订
  BOOKED: 1,
  // 已开门
  OPEN_DOOR: 2,
  //  已结算
  SETTLE: 3,
  // 已超时(未开门)
  TIME_OUT: 4,
  // 已取消
  CANCEL: 5,
  //  已支付
  PAID: 6,
};
// 会议室类型
const MEETING_ROOM_TYPE = {
  PUBLIC: 2,
  PRIVATE: 1,
};
export default {
  name: "MeetingRoomORder",
  created() {
    window.addEventListener("keydown", this.getMeetingRoomOrdersDown);
  },
  destroyed() {
    window.removeEventListener("keydown", this.getMeetingRoomOrdersDown);
  },

  deactivated() {
    window.removeEventListener("keydown", this.getMeetingRoomOrdersDown);
  },
  components: {
    CSDialog,
    CSTable,
    ViewModal,
    RefundChargeModal,
    Pagination,
    CSSelect,
  },
  filters: {
    realMoney(val) {
      return val ? val / 100 + "元" : "-";
    },
  },
  data() {
    return {
      PAY_CHANNEL,
      offlinePaymentDialog: {
        visible: false,
        data: {
          id: "",
          moneyAmount: "",
          payChannel: "",
          paymentVoucher: "",
          desc: "",
        },
      },
      refundModalInfo: {
        visible: false,
        detailId: 0,
        money: 0,
        refresh: this.getMeetingRoomOrders,
      },
      filterHeight: 0,
      ORDER_STATE,
      MEETING_ROOM_TYPE,
      meetingRoomTypes: [
        {type: MEETING_ROOM_TYPE.PUBLIC, name: "共享会议室"},
        {type: MEETING_ROOM_TYPE.PRIVATE, name: "私有会议室"},
      ],
      orderStates: {
        [ORDER_STATE.BOOKED]: "已预订",
        [ORDER_STATE.OPEN_DOOR]: "使用中",
        [ORDER_STATE.SETTLE]: "已结算",
        [ORDER_STATE.TIME_OUT]: "已过期",
        [ORDER_STATE.CANCEL]: "已取消预约",
        [ORDER_STATE.PAID]: "已支付",
      },
      oldStates: {
        [ORDER_STATE.BOOKED]: "已预订",
        [ORDER_STATE.OPEN_DOOR]: "使用中",
        [ORDER_STATE.SETTLE]: "已结束",
        [ORDER_STATE.TIME_OUT]: "已过期",
        [ORDER_STATE.CANCEL]: "已取消预约",
      },
      startDate: dayjs().subtract(30, "day").format("YYYY-MM-DD"),
      endDate: dayjs().format("YYYY-MM-DD"),
      userInfo: "",
      regionId: this.$vc.getCurrentRegion().communityId,
      state: "",
      type: MEETING_ROOM_TYPE.PUBLIC,

      orderList: [],
      workOrderQueryParam: {
        startTime: new Date(dayjs().add(30, "day").valueOf()),
        endTime: new Date(),
        regionId: "", //必填 区域id
        type: 1, //必填 类型 1.私有会议室;2.共享会议室;
        date: "", //必填 查询日期 默认当天
        // "searchCompanyName":"",//选填 搜索公司名称
        roomId: 1, //选填 会议室id
      },
      pageParams: {
        pageNo: 1,
        pageSize: 10
      }
    };
  },
  updated() {
    this.filterHeight = document.querySelector(".filter-panel").offsetHeight;
  },
  methods: {
    triggerUploadElement(el) {
      document.querySelector(el).click();
    },
    offlinePay() {
      if (this.offlinePaymentDialog.data.moneyAmount === "") {
        this.$vc.message("请输入支付金额");
        return;
      }
      if (this.offlinePaymentDialog.data.paymentVoucher === "") {
        this.$vc.message("请选择付款凭证");
        return;
      }
      this.$fly
          .post(meetingRoomOfflinePaymentUrl, this.offlinePaymentDialog.data)
          .then((res) => {
            if (res.code === 0) {
              this.offlinePaymentDialog.visible = false;
              this.getMeetingRoomOrders();
            }
          });
    },
    // 打开线下付款
    openOfflinePaymentDialog(item) {
      this.offlinePaymentDialog.data = {
        id: item.roomDetail,
        moneyAmount: item.moneyAmount,
        payChannel: "",
        paymentVoucher: "",
        desc: "",
      };
      this.offlinePaymentDialog.visible = true;
    },
    deletePayVoucher(index) {
      this.offlinePaymentDialog.data.paymentVoucher.splice(index, 1);
    },
    /**
     * 选择文件
     * @param {Object} event 当前元素
     *  */
    chooseFile(event) {
      return this.$vc.chooseFile(event, this.uploadAttachment);
    },
    choosePhoto(event) {
      return this.$vc.chooseFile(event, async (file) => {
        const uploadResult = await this.$vc.uploadFile(file);
        if (uploadResult.code !== 0) {
          return;
        }
        this.offlinePaymentDialog.data.paymentVoucher =
            uploadResult.data.fileSaveName;
      });
    },
    getMeetingRoomOrdersDown(e) {
      if (e.keyCode == 13) {
        this.getMeetingRoomOrders();
      }
    },
    /**
     * 打开退款弹窗
     * @param {Object} order 订单信息
     *
     *  */
    openRefundModal(order) {
      this.refundModalInfo = {
        visible: true,
        detailId: order?.roomDetail || 0,
        money: order?.payMoney || 0,
        refresh: this.getMeetingRoomOrders,
      };
    },
    closeRefundModal() {
      this.refundModalInfo.visible = false;
    },
    /**
     * 切换tabBar
     * @param {Number} type 1: 私有 2:共享
     *  */
    changeTabBar(type) {
      this.pageParams.pageNo = 1;
      if (this.type !== type) {
        this.type = type;
        this.getMeetingRoomOrders();
      }
    },
    /**
     * 查看会议室房间号
     * @param {Object} meetingRoom 房间信息
     * */
    lookMeetingRoomNo(meetingRoom) {
      this.$CSDialog.alert({
        title: "查看",
        message: meetingRoom.buildingName + "-" + meetingRoom.name,
      });
    },
    /**
     * 查看用户评价
     * @param {Object} logInfo log信息
     *  */
    lookEvaluate(logInfo) {
      this.$CSDialog.alert({
        title: "用户评价",
        messageHtml: `
                    <div style="font-size:24px;color: #000;display:block;" class="text-left">
                        <div style="margin-bottom:30px">
                            <span style="width:100px;margin-right: 30px;">评价时间</span>
                            <div style="display:inline-block;">
                            ${logInfo.createTime}
                            </div>
                        </div>
                        <div style="margin-bottom:30px">
                            <span style="width:100px;margin-right: 30px;">评价星级</span>
                            <div style="display:inline-block;">
                            ${logInfo.score}星
                            </div>
                        </div>
                        <div>
                            <span style="width:100px;margin-right: 30px;vertical-align:top;">评价内容</span>
                            <div style="display:inline-block;vertical-align:top;width:380px;">
                                ${logInfo.evaluate}
                            </div>
                        </div>
                    </div>
                `,
      });
    },
    //  获取订单列表
    getMeetingRoomOrders(pageNo = this.pageParams.pageNo, pageSize = 10) {
      const {startDate, endDate, userInfo, regionId, state, type} = this;
      this.$fly
          .post(queryMeetingRoomOrderUrl, {
            startDate,
            endDate,
            userInfo,
            regionId,
            state,
            type,
            pageNo,
            pageSize,
          })
          .then((res) => {
            if (res.code != 0) {
              return;
            }
            if (pageNo === 1) {
              this.$vc.emit(this.$route.path, "pagination", "init", {
                total: res.data.total,
                currentPage: pageNo,
                pageSize,
              });
            }
            this.orderList = res.data;
          });
    },
    // 开门事件
    itemOpen(RoomId) {
      this.$fly
          .post(sweepCodeToOpenDoorlUrl, {
            id: RoomId, //订单id
          })
          .then((res) => {
            alert("开门成功");
          });
    },
    // 关门事件
    itemClose(RoomId) {
      this.$fly
          .post(closeDoorSettlementUrl, {
            id: RoomId,
          })
          .then((Response) => {
            alert(Response, "关门成功");
          });
    },
  },
  mounted() {
    this.getMeetingRoomOrders();
    this.$vc.on(this.$route.path, "pagination", "page_event", (pageNo) => {
      this.pageParams.pageNo = pageNo;
      this.getMeetingRoomOrders(pageNo);
    });
    this.filterHeight = document.querySelector(".filter-panel").offsetHeight;
    window.addEventListener("resize", () => {
      this.filterHeight = document.querySelector(".filter-panel").offsetHeight;
    });
  },
};
</script>

<style lang="stylus" scoped>
.offline-payment-dialog {
  .box_ {
    font-size: 24px;
  }

  input[type='radio'] {
    display: inline-block;
    vertical-align: middle;
    bottom: 2px;
  }

  .inputSpace {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
  }

  .hy-label {
    width: 96px;
    text-align: right;
    vertical-align: top;
    margin: 0 40px 0 0;
  }

  .hy-remarks {
    width: 96px;
    margin-bottom: 0;
    text-align: right;
    vertical-align: top;
    margin-right: 40px;
    height: 24px;
    line-height: 24px;

    & + textarea {
      resize: none;
      background: #f0f0f0;
      width: 720px;
      height: 200px;
      border-radius: 4px;
      padding-left: 10px;
    }
  }

  .box_ {
    margin-bottom: 14px;
  }
}

.filter-panel {
  .cs-input {
    border: 1px solid #979797;
    // border-radius 1px
    vertical-align: middle;
    width: 240px;
    height: 30px;
  }

  // margin 0 20px
  // margin-bottom 15px
  // padding 0 10px
  // box-sizing border-box

  /deep/ .cs-select {
    display: inline-block;
    border: 1px solid #979797;
    height: 30px;
    overflow: hidden;
    border-radius: 4px;
    vertical-align: middle;

    select {
      border: none;
      background: #fff;
      padding-right: 26px;
      margin-right: -26px;
      outline: none;
    }

    .icon {
      width: 26px;
      text-align: center;
      border-left: 1px solid #979797;
      height: 30px;
      line-height: 30px;
      vertical-align: middle;
      position: relative;
      background: #fff;
      z-index: 1;
    }
  }
}

// .el-date-editor
// height 30px
// vertical-align middle
// input
// height 30px
// border 0
// .el-input__prefix, .el-input__suffix
// height 30px
// .el-input__icon
// line-height 30px
.tab-bar > div {
  border-radius: 4px;
}

.tab-bar > div:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.tab-bar > div:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

tbody > .text-center {
  vertical-align: middle !important;
}

.text-center > a:hover {
  color: #1DAFFF;
  text-decoration: underline;
}

.text-center > p {
  margin-bottom: 0px;
}

.table > tbody > tr > td, .table > tfoot > tr > td {
  vertical-align: middle;
}

.table > thead:first-child > tr:first-child > th {
  border-top: 1px solid #e7eaec;
}

.query_ {
  position: relative;
  top: 2px;
  left: 0;
  width: 50px;
  height: 30px;
  background: #1ab394;
  box-shadow: 0px 5px 10px 0px rgba(0, 182, 148, 0.2);
  border-radius: 4px;
  border: none;
  color: #fff;
}

// padding-top 6px
input::-webkit-input-placeholder {
  color: #999;
}

input::-moz-placeholder {
  color: #999;
}

input::-moz-placeholder {
  color: #999;
}

input::-ms-input-placeholder {
  color: #999;
}
</style>
